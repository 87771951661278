import { styled } from '@material-ui/core';

import Image from 'next/image';

export const VideoMask = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: -10,
  borderRadius: '13px',
  zIndex: 1,

  height: 370,
  width: 204,

  '& svg': {
    width: '45px',
  },
});

export const Img = styled(Image)({
  borderRadius: 10,
});
