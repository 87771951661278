import { useCallback, useEffect, useRef, useState } from 'react';

import { Box } from '@material-ui/core';
import dynamic from 'next/dynamic';
import { useTracking } from '@hooks/tracking';
import Card from './Card';

import { ContainerDesktop, Container } from './styles';
import Skeleton from './Skeleton';
import { ListProps } from '.';

const Arrow = dynamic(() => import('./ArrowsControl'), { ssr: false });

const StoriesList: React.FC<ListProps> = ({ contents, className }) => {
  const { track } = useTracking();
  const perPage = 4;
  const refContainerDesktop = useRef<HTMLDivElement>(null);
  const refContainerList = useRef<HTMLDivElement>(null);
  const [hasEndedScroll, setHasEndedScroll] = useState(false);
  const [page, setPage] = useState(1);
  const [containerDesktopWidth, setContainerDesktopWidth] = useState(0);
  const [contentsPage, setContentsPage] = useState(contents?.slice(0, 1));

  useEffect(() => {
    if (refContainerDesktop.current && contents?.length > 0) {
      const contentsPerPage = Math.round(containerDesktopWidth / 230) + 1;
      if (contentsPerPage >= contents.length) setHasEndedScroll(true);
      setContentsPage(contents.slice(0, contentsPerPage));
    }
  }, [contents, containerDesktopWidth]);

  const handleClientWidth = useCallback(() => {
    setContainerDesktopWidth(refContainerDesktop.current?.clientWidth);
  }, []);

  useEffect(() => {
    handleClientWidth();
    window.addEventListener('resize', handleClientWidth, {
      passive: true,
    });
    return () => {
      window.removeEventListener('resize', handleClientWidth);
    };
    // eslint-disable-next-line
  }, []);

  const handleNextPage = (scrollLeft: number, clientWidth: number) => {
    if (
      scrollLeft + containerDesktopWidth >= clientWidth &&
      contentsPage.length < contents.length
    ) {
      setPage(page + 1);
      setContentsPage(contents.slice(0, (page + 1) * perPage));
    }
  };

  const handleScroll = (event: 'next' | 'prev') => {
    if (refContainerList.current) {
      const valueScroll =
        event === 'next'
          ? refContainerList.current.clientWidth
          : -refContainerList.current.clientWidth;

      refContainerList.current.scrollTo({
        left: refContainerList.current.scrollLeft + valueScroll,
        behavior: 'smooth',
      });
    }
  };

  return (
    <ContainerDesktop ref={refContainerDesktop} className={className}>
      {!hasEndedScroll && (
        <Box position="relative">
          <Arrow
            isRight
            left={containerDesktopWidth - 110}
            top={175}
            onClick={() => handleScroll('next')}
          />
          <Arrow top={175} left={-35} onClick={() => handleScroll('prev')} />
        </Box>
      )}
      <Container
        innerRef={refContainerList}
        vertical
        horizontal
        hideScrollbars
        onScroll={(scrollLeft, _scrollTop, scrollWidth) =>
          handleNextPage(scrollLeft, scrollWidth)
        }
      >
        {contentsPage?.map(content => (
          <Card
            key={content.slug}
            {...content}
            onClickTracking={() => {
              track('Clicked in highlights section', {
                contentTitle: content.title,
              });
            }}
          />
        )) || <Skeleton />}
      </Container>
    </ContainerDesktop>
  );
};

export default StoriesList;
